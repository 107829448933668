import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import divider from 'assets/images/Line_topic.png';

import Group24 from '../../assets/images/topics/group-24.inline.svg';
import banner from '../../assets/images/topics/customer.jpg';
import Group27 from '../../assets/images/topics/group-27.inline.svg';
import Asset from '../../assets/images/topics/Asset.inline.svg';
import CTATopic from '../../components/CTATopic';

import speaker1 from '../../assets/images/topics/NancyRademaker.jpg';
import speaker2 from '../../assets/images/topics/Steven.jpg';

const Customer = () => {
  return (
    <Layout>
      <Helmet>
        <title>EY - The enlightened session</title>
      </Helmet>
      <div className='topicTemplate'>
        <Container>
          <div className='topicIntro'>
            <div className='banner'>
              <img src={banner} alt='Customer Banner' />
            </div>
            <div className='text'>
              <Row>
                <Col>
                  <h1>Customer</h1>

                  <div className='introText'>
                    <p>
                      Increasingly, customers participate across the entire
                      energy value chain. They influence the sources of their
                      energy directly; they choose how they consume it and,
                      increasingly, they generate, store and even sell their own
                      power.
                    </p>
                    <p>
                      In this new energy era, a legion of disruptors, from
                      non-traditional sectors, is beginning to disrupt the
                      traditional marketplace. Their emergence is a consequence
                      of:
                    </p>
                    <ul>
                      <li>
                        ethically enlightened next-generation energy customers
                      </li>
                      <li>increasingly ubiquitous technologies </li>
                      <li>favorable regulations</li>
                      <li>declining barriers to market entry </li>
                    </ul>
                    <p>
                      To succeed in this competitive ecosystem, energy providers
                      will seek innovative and consumer-centric solutions to
                      achieve sustainable growth. They will leverage their core
                      competencies to deliver at scale.
                    </p>
                  </div>
                </Col>
                <Col />
              </Row>
            </div>
          </div>
          <div className='divider'>
            <img src={divider} alt='divider' />
          </div>
          <div className='speakers'>
            <div className='speaker'>
              <div>
                <img src={speaker1} alt='Nancy Rademaker' />
              </div>
              <div className='speakerIntro'>
                <h1>Nancy Rademaker</h1>

                <div className='introText'>
                  <p>
                    International keynote speaker Nancy Rademaker explains the
                    “X Factor of Customer Centricity”.{' '}
                  </p>
                  <p>
                    For the past few decades, there has been greater focus on
                    customer experience [CXT]. The choice of products and
                    services is governed increasingly by customer emotions. In
                    an age where everything is available to everyone,
                    organizations have come to recognize that customer
                    centricity is key, while the experience provides the
                    distinguishing X factor.
                  </p>
                  <p>
                    Nancy will take you on a journey through human change. She
                    will explore the effects of change on organizations and the
                    reasons why customer centricity and CX are essential to
                    acquiring and retaining customers. This strategy has
                    technology at its core. Artificial intelligence, algorithms
                    and new interfaces will help businesses to define and
                    satisfy the needs of their new customer.
                  </p>
                </div>
              </div>
            </div>

            <div className='speaker'>
              <div>
                <img src={speaker2} alt='Steven Van Belleghem' />
              </div>
              <div className='speakerIntro'>
                <h1>Steven Van Belleghem</h1>

                <div className='introText'>
                  <p>
                    An expert in customer-centricity in a digital world, Steven
                    Van Belleghem acknowledges that 4G, social media and mobile
                    services have given rise to a “new minimum” in customer
                    experience. He says customers expect ease of use, friendly
                    and empathic staff, omnichannel services and competitive
                    prices. In the years to come, customer experience will reach
                    a whole new level.
                  </p>
                  <p>
                    Technology will play a key role in delivering on customer
                    expectations, but Steve wants to know how companies can
                    “help consumers’ dreams come true and eliminate the
                    obstacles in their day-to-day lives.” He believes companies
                    can tackle world problems together with their customers, and
                    even develop solutions to challenges such as climate change
                    and mobility.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='topicExpect'>
            <h1>
              What you <span>can expect?</span>
            </h1>
            <div>
              <p>
                This enlightened session offers you clear insight into the
                myriad of challenges and opportunities facing the energy
                industry today.
              </p>
              <p>
                Our keynote speakers will prepare you for the next gen.
                customer. We will follow with an interactive session, focused
                specifically on your organization and the customer challenges it
                faces.
              </p>
            </div>
            <div className='expectWrapper'>
              <div className='expectItem'>
                <Asset className='asset' />
                <h6>The new age</h6>
                <p>
                  New technologies have created new possibilities and channels
                  for interacting with customers. Find out how relationships
                  with customers will evolve as technologies become more
                  enabling.
                </p>
                <p>
                  <em>Company-wide</em>
                </p>
              </div>
              <div className='expectItem'>
                <Group24 />
                <h6>Keynote session</h6>
                <p>
                  Nancy or Steven will inspire you with their latest insights on
                  customer trends. Understand the key characteristics of the
                  next gen. customer and how best to interact with them.
                </p>
                <p>
                  <em>Company-wide</em>
                </p>
              </div>
              <div className='expectItem'>
                <Group27 />
                <h6>Interactive discussion</h6>
                <p>
                  We put the spotlight on your future customer. Explore ways in
                  which your customers will evolve, how they will influence your
                  operations, as well as the types of new customer you can
                  expect to serve in the coming decade.
                </p>
                <p>
                  <em>Limited audience</em>
                </p>
              </div>
            </div>
          </div>
          <CTATopic
            title={'Customer'}
            description={
              'Join in this unique experience. Sign up for this exclusive enlightened session and get to know your next gen. customer better.'
            }
          />
        </Container>
      </div>
    </Layout>
  );
};
export default Customer;
